// @flow
import * as React from "react";
import Image from "next/image";
import icon12 from "../../assets/images/icon12.png";
import icon13 from "../../assets/images/icon13.png";
import icon14 from "../../assets/images/icon14.png";
export default function ContentSection(props) {
  return (
    <section className="contact-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="contact-details-wrap">
              <div className="row">
                <div className="col-sm-4">
                  <div className="contact-details">
                    <div className="contact-icon">
                      <Image loading="lazy" alt="" src={icon12} />
                    </div>
                    <ul>
                      <li>
                        <a href="#">{props.email}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="contact-details">
                    <div className="contact-icon">
                      <Image loading="lazy" alt="" src={icon13} />
                    </div>
                    <ul>
                      <li>
                        <a href="#"> {props.mainphone}</a>
                      </li>
                      <li>
                        <a href="#">{props.localphone}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="contact-details">
                    <div className="contact-icon">
                      <Image loading="lazy" alt="" src={icon14} />
                    </div>
                    <ul>
                      <li>{props.address}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
