// @flow
import * as React from "react";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import axiosconfig from "../../config/axios-config";
import Link from "next/link";

export default function ClientSection() {
  const [partnerLogo, setpartnerLogo] = useState([]);
  const [pageContent, setPageContent] = useState([]);
  const [pageContainer, setPageContainer] = useState([]);
  useEffect(() => {
    panersDataFn();
    getPageContentData();
    getPageContainerData();
  }, []);

  async function getPageContentData() {
    const respone = await axiosconfig.get("website/pagecontainer", {
      params: {
        pageNumber: 0,
      },
    });
    const pages = await respone.data.data;
    setPageContent(pages);
  }

  async function getPageContainerData() {
    const respone = await axiosconfig.get("website/pagemodule", {
      params: {
        pageNumber: 0,
      },
    });
    const pages = await respone.data.data;
    setPageContainer(pages);
  }

  async function panersDataFn() {
    const res = await axiosconfig.get("website/partnerlogo");

    const alldata = await res.data;

    setpartnerLogo(alldata.data);
  }

  var logoShow = [];
  partnerLogo.forEach((logo) => {
    if (logo.isHomeShown == true) {
      logoShow.push(logo);
    }
  });

  console.log(partnerLogo);
  const clientSlider = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: logoShow.length - 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="client-section">
      <div className="container">
        <Slider className="client-wrap client-slider row" {...clientSlider}>
          {logoShow.map((log) => {
            return (
              <>
                <div className={`client-item order-${log.order}`}>
                  {log.isExternalLink == true ? (
                    <a href={log.externalLink} target="_blank" rel="noreferrer">
                      <figure>
                        <img alt={log.title} src={log.logo} />
                      </figure>
                    </a>
                  ) : log?.pageContainerId?.length > 0 ? (
                    pageContent.map((pagecontiner) => {
                      return (
                        <>
                          {pagecontiner?.id === log?.pageContainerId &&
                          pagecontiner?.pageSubModuleId?.length > 0 ? (
                            pageContainer.map((pageModule) => {
                              return (
                                <>
                                  {pageModule?.id ===
                                    pagecontiner?.pageModuleId && (
                                    <Link
                                      href={`/${pageModule?.name
                                        .replace(
                                          /[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi,
                                          ""
                                        )
                                        .replace(/\s+/g, " ")
                                        .replace(" ", "-")
                                        .split(" ")
                                        .join("-")}/${
                                        pagecontiner?.pageSEO.pageURL
                                      }`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <a>
                                        <img
                                          alt={log.title}
                                          src={log.logo ? log.logo : ""}
                                        />
                                      </a>
                                    </Link>
                                  )}
                                </>
                              );
                            })
                          ) : pagecontiner?.id === log?.pageContainerId ? (
                            <Link
                              href={`/${pagecontiner?.pageSEO?.pageURL}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <a>
                                <img
                                  alt={log.title}
                                  src={log.logo ? log.logo : ""}
                                />
                              </a>
                            </Link>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })
                  ) : (
                    <figure>
                      <img alt={log.title} src={log.logo ? log.logo : ""} />
                    </figure>
                  )}
                </div>
              </>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
