// @flow
import * as React from "react";
import { useEffect, useState } from "react";
import axiosconfig from "../../config/axios-config";

export default function SubscribeSection() {
  const [subscribeSection, setSubscribeSection] = useState([]);

  useEffect(() => {
    getSubscribeSection();
  }, []);

  async function getSubscribeSection() {
    const sections = await axiosconfig.get("website/homepagesections");

    const pageSections = await sections.data.data[0]?.newsletterData;
    setSubscribeSection(pageSections);
  }
  return (
    <section
      className="subscribe-section"
      style={{
        backgroundImage: `url(${
          subscribeSection?.image ? subscribeSection?.image : "url(/bg.jpg)"
        })`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="section-heading section-heading-white">
              {subscribeSection.subTitle?.length > 0 && (
                <h4>{subscribeSection.subTitle}</h4>
              )}
              <h2>{subscribeSection.title}</h2>
              <h4>{subscribeSection.description}</h4>
              <div className="newsletter-form">
                <form>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email Address"
                  />
                  <input type="submit" name="signup" value="SIGN UP NOW!" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
